export default class Functions {
  downloadFile(apiResponse) {
    const filename = apiResponse.headers['content-disposition'].split('"');
    const fileURL = window.URL.createObjectURL(new Blob([apiResponse.data]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename[1]);
    document.body.appendChild(fileLink);

    fileLink.click();
  }

  downloadBase64File(apiResponse) {
    const filename = apiResponse.headers['content-disposition'].split('"');
    const contentType = apiResponse.headers['content-type'];
    const fileLink = document.createElement('a');

    fileLink.href = `data:${contentType};base64,${apiResponse.data}`;
    fileLink.setAttribute('download', filename[1]);
    document.body.appendChild(fileLink);

    fileLink.click();
  }

  downloadURI = (uri) => {
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  clearSessionStorage(keysToPreserve) {
    const preservedValues = {};

    keysToPreserve.forEach((key) => {
      const value = sessionStorage.getItem(key);
      if (value !== null) {
        preservedValues[key] = value;
      }
    });

    sessionStorage.clear();

    Object.entries(preservedValues).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
  }
}
