<template>
  <v-container fluid class="mx-2">
    <ViewTitles>
      <h1 slot="title">{{ titlePage }}</h1>
    </ViewTitles>

    <v-row class="mr-2">
      <v-col cols="12">
        <v-tabs
          color="primary"
          background-color="var(--v-background-base)"
          v-model="tab"
          class="mb-4"
        >
          <v-tab key='main' href='#main' style="font-size: 18px">Principal</v-tab>
          <v-tab v-if="$route.params.type === 'batch'" key='removedMovements' href='#removedMovements' style="font-size: 18px">Movimentações Removidas</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" grow>
          <v-tab-item key='main' value='main'>
            <v-data-table
              class="elevation-1"
              item-key="id"
              hide-default-footer
              :headers="mainHeaders"
              :items="logs"
              :loading="loadingLogs"
              :items-per-page="10"
            >
              <template v-slot:no-data>
                <span>Nenhum dado encontrado.</span>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                {{ item.id ? item.id : '-' }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt ? formatter.formatDateTimeZoneWithHours(item.createdAt) : '-' }}
              </template>
              <template v-slot:[`item.userName`]="{ item }">
                {{ item.userName ? item.userName : '-' }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                {{ item.action === 'API_REQUESTED' ? 'Chamada de API' : item.action === 'BATCH_STATUS_CHANGED' ? 'Alteração de status do lote' : item.action === 'FILE_GENERATED' ? 'Geração de arquivo' : '-' }}
              </template>
              <template v-slot:[`item.description`]="{ item }">
                {{ item.description ? translateStatus(item.description) : '-' }}
              </template>
              <template v-slot:[`item.contentKey`]="{ item }">
                <div v-if="item.contentKey">
                  <v-btn small text color="primary" @click="onClickDownload(item)">
                    <v-icon>
                      fas fa-download
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`header.contentKey`]="{ header }">
                <span>{{ hasContentKey ? header.text : null }}</span>
              </template>
            </v-data-table>
            <div class="d-flex justify-end mt-2">
              <v-pagination
                v-model="page"
                color="textPrimary"
                :length="totalPages"
                :disabled="loadingLogs"
                :total-visible="9"
                @input="loadLogs()"
              />
            </div>
          </v-tab-item>
          <v-tab-item key='removedMovements' value='removedMovements'>
            <v-data-table
              class="elevation-1"
              item-key="id"
              hide-default-footer
              :headers="removedMovementsHeaders"
              :items="removedMovements"
              :loading="loadingRemovedMovements"
              :items-per-page="10"
            >
              <template v-slot:no-data>
                <span>Nenhum dado encontrado.</span>
              </template>
              <template v-slot:[`item.financialGroupId`]="{ item }">
                {{ item.financialGroupId ? formatter.formatFinancialGroup(item.financialGroupId, financialGroups) : '-' }}
              </template>
              <template v-slot:[`item.movementType`]="{ item }">
                {{ item.movementType ? formatter.movementTypeMessage(item.movementType) : '-' }}
              </template>
              <template v-slot:[`item.insuredName`]="{ item }">
                {{ item.insuredName ? formatter.formatToTitleCase(item.insuredName) : '-' }}
              </template>
              <template v-slot:[`item.insuredDocumentNumber`]="{ item }">
                {{ item.insuredDocumentNumber ? formatter.formatCpf(item.insuredDocumentNumber) : '-' }}
              </template>
              <template v-slot:[`item.beneficiaryType`]="{ item }">
                {{ item.beneficiaryType ? formatter.formatBeneficiary(item.beneficiaryType) : '-' }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ item.status ? formatter.formatStatusMessage(item.status) : '-' }}
              </template>
            </v-data-table>
            <div class="d-flex justify-end mt-2">
              <v-pagination
                v-model="pageRemovedMovements"
                color="textPrimary"
                :length="totalPagesRemovedMovements"
                :disabled="loadingRemovedMovements"
                :total-visible="9"
                @input="loadRemovedMovements()"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-footer class="my-10 mr-1">
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn x-large block outlined color="textPrimary" @click="onClickBack()">
            Voltar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import ViewTitles from '@/components/PageTitles/ViewTitles.vue';
import InsuranceCarrierMovementActionService from '@/services-http/sdi/InsuranceCarrierMovementActionService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import Formatters from '@/shared/formatters/formatters';
import Functions from '@/shared/functions/functions';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';

export default ({
  components: { ViewTitles, SnackbarCustomize },

  data: () => ({
    loadingLogs: false,
    loadingRemovedMovements: false,
    isRHProtegido: false,
    isMovementType: false,

    tab: 'main',
    page: 1,
    pageRemovedMovements: 1,
    totalPages: 0,
    totalPagesRemovedMovements: 0,
    size: 10,

    options: {},

    queryString: '',
    logs: [],
    removedMovements: [],

    removedMovementsHeaders: [
      {
        text: 'ID da Movimentação',
        value: 'id',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Grupo Financeiro',
        value: 'financialGroupId',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Contrato',
        value: 'policy',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Sub-Contrato',
        value: 'subContract',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Operadora',
        value: 'insuranceCarrier',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo da Movimentação',
        value: 'movementType',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Nome Beneficiário',
        value: 'insuredName',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'CPF',
        value: 'insuredDocumentNumber',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Tipo do beneficiário',
        value: 'beneficiaryType',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Status Atual',
        value: 'status',
        align: 'start',
        class: 'text--truncate',
        sortable: false,
      },
    ],
  }),

  mixins: [
    VerifyRoutesMixin,
    FinancialGroupsMixin,
  ],

  computed: {
    titlePage() {
      return this.$route.params.type === 'movement' ? 'Registro de Movimentações' : 'Registro de Lotes';
    },
    hasContentKey() {
      return this.logs.some((log) => log.contentKey);
    },
    mainHeaders() {
      const headers = [
        {
          text: 'ID',
          value: 'id',
          align: 'start',
          class: 'text--truncate',
          sortable: false,
        },
        {
          text: 'Data/hora',
          value: 'createdAt',
          align: 'start',
          class: 'text--truncate',
          sortable: false,
        },
        {
          text: 'Usuário',
          value: 'userName',
          align: 'start',
          class: 'text--truncate',
          sortable: false,
        },
        {
          text: 'Ações',
          value: 'action',
          align: 'start',
          class: 'text--truncate',
          sortable: false,
        },
        {
          text: 'Descrição/resultado',
          value: 'description',
          align: 'start',
          class: 'text--truncate',
          sortable: false,
        },
      ];

      if (this.logs.some((log) => log.contentKey)) {
        headers.push({
          text: 'Opções',
          value: 'contentKey',
          align: 'center',
          class: 'text--truncate',
          sortable: false,
        });
      }

      return headers;
    },
  },

  mounted() {
    this.loadLogs();
    this.getFinancialGroups();
  },

  methods: {
    loadLogs() {
      this.loadingLogs = true;
      const { id } = this.$route.params;
      this.isMovementType = this.$route.params.type === 'movement';

      const params = {
        page: this.page - 1,
        size: this.size,
      };

      if (this.isMovementType) {
        params.movementRecordId = id;
      } else {
        params.movementInsuranceCarrierId = id;
        this.loadRemovedMovements();
      }

      const query = new URLSearchParams(params);
      query.append('sort', 'id,desc');
      this.insuranceCarrierMovementActionService.FindLogsByParams(query).then((response) => {
        if (response && response.data && response.data.content && response.data.content.length > 0) {
          this.logs = response.data.content;
          this.totalPages = response.data.totalPages;
        }
        this.loadingLogs = false;
      }).catch(() => {
        this.loadingLogs = false;
        this.$refs.SnackbarCustomize.open('error', 'Erro ao buscar logs. Tente novamente');
      });
    },
    onClickDownload(item) {
      this.insuranceCarrierMovementActionService.DownloadActionById(item.id).then((response) => {
        this.functions.downloadURI(response.data);
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao fazer o download. Tente novamente');
      });
    },
    loadRemovedMovements() {
      const params = {
        page: this.pageRemovedMovements - 1,
        size: this.size,
      };
      params.previousMovementInsuranceCarrier = this.$route.params.id;
      this.loadingRemovedMovements = true;
      const query = new URLSearchParams(params);
      query.append('sort', 'id,desc');
      this.movementRecordService.FindRemovedMovementByBatchId(query).then((response) => {
        if (response && response.data && response.data.content && response.data.content.length > 0) {
          this.removedMovements = response.data.content;
          this.totalPagesRemovedMovements = response.data.totalPages;
        }
        this.loadingRemovedMovements = false;
      }).catch(() => {
        this.loadingRemovedMovements = false;
        this.$refs.SnackbarCustomize.open('error', 'Erro ao buscar movimentações removidas do lote. Tente novamente');
      });
    },
    onClickBack() {
      this.$router.push({ name: 'Movement', query: { isRHProtegido: `${this.isRHProtegido}` } });
    },
    translateStatus(status) {
      const statusFormatted = status.split(' -> ');
      if (statusFormatted && statusFormatted.length === 2) {
        return `${this.formatter.batchStatusFormatter(statusFormatted[0])} -> ${this.formatter.batchStatusFormatter(statusFormatted[1])}`;
      }
      return status;
    },
  },

  created() {
    this.insuranceCarrierMovementActionService = new InsuranceCarrierMovementActionService();
    this.movementRecordService = new MovementRecordService();
    this.formatter = new Formatters();
    this.functions = new Functions();
  },
});
</script>
