/* eslint-disable */
import HttpService from '../HttpService'

export default class MovementRecordService {
  constructor() {
    this._httpService = new HttpService('/sdi/movement-record');
    this._httpSdiService = new HttpService('/sdi');
  }

  async RegisterInsert(data){
    return await this._httpService.post('/insert', data);
  }

  async UpdateFamilyGroup(id, freeMovement, data, isCriticizedBroker) {
    return await this._httpService.put(`/${id}/family-group?isFreeMovement=${freeMovement}&isCriticizedBroker=${isCriticizedBroker}`, data);
  }

  async ValidateInsert(data, fieldsToValidate){
    return await this._httpService.post(`/insert-validation?fieldsToValidate=${fieldsToValidate}`, data);
  }

  async ValidateImplantation(data, fieldsToValidate){
    return await this._httpService.post(`/implantation-validation?fieldsToValidate=${fieldsToValidate}`, data);
  }

  async RegisterImplantation(data){
    return await this._httpService.post('/implantation', data);
  }

  async InsertEventualDependents(data, freeMovement) {
    return await this._httpService.post(`/eventual-dependent?isFreeMovement=${freeMovement}`, data);
  }

  async UpdateEventualDependents(id, data, freeMovement, isCriticizedBroker) {
    return await this._httpService.put(`/${id}/dependent-update?isFreeMovement=${freeMovement}&isCriticizedBroker=${isCriticizedBroker}`, data);
  }

  async GetMovementCriticismByMovementRecordId(movementRecordId) {
    return await this._httpService.get('/' + movementRecordId + '/movement-rule-violation-record');
  }

  async GetMovementCriticismByMovementRecordIds(movementRecordIds) {
    return await this._httpService.get(`/movement-rule-violation-record/${movementRecordIds}`);
  }

  async GetMovementRecordById(id) {
    return await this._httpService.get(`/${id}`);
  }

  async GetMovementRecordByIdChangedFields(id) {
    return await this._httpService.get(`/${id}/changed-fields`);
  }

  async GetMovementRecordByChangedFieldsByParentId(ParentId) {
    return await this._httpService.get(`/changed-fields/${ParentId}`);
  }

  async FindFamilyGroupByParams(params) {
    return await this._httpService.get(`/${params}`);
  }

  async FindDependentsByFamilyGroupId(familyGroupId) {
    return await this._httpService.get(`/findDependentsByFamilyGroupId/${familyGroupId}`);
  }

  async FindByParams(params) {
    return await this._httpService.get('/v2', params);
  }

  async FindAllIds(params) {
    return await this._httpService.get('/find-all-ids', params);
  }

  async GetReasonCancellation(params) {
    const result = await this._httpSdiService.get('/reason-cancellation', params);
    return result.data;
  }

  async UpdateDependent(data, freeMovement) {
    return await this._httpService.post(`/update/dependent?isFreeMovement=${freeMovement}`, data);
  }

  async UpdateAlterationDependent(id, data, freeMovement, isCriticizedBroker) {
    return await this._httpService.put(`${id}/dependent-update?isFreeMovement=${freeMovement}&isCriticizedBroker=${isCriticizedBroker}`, data);
  }

  async UpdateHolder(data, freeMovement) {
    return await this._httpService.post(`/update/holder?isFreeMovement=${freeMovement}`, data);
  }

  async UpdateAlterationHolder(id, data, freeMovement, isCriticizedBroker) {
    return await this._httpService.put(`${id}/holder-update?isFreeMovement=${freeMovement}&isCriticizedBroker=${isCriticizedBroker}`, data);
  }

  async UpdateStatus(id, status) {
    return await this._httpService.put(`/${id}/status/${status}`);
  }

  async UpdateStatusFromMultipleMovements(data) {
    return await this._httpService.put('/status-multiple-movements', data);
  }

  async Reactivate(data) {
    return await this._httpService.post('/reactivation', data);
  }

  async Delete(data, freeMovement) {
    return await this._httpService.post(`/deleteManualMovement?isFreeMovement=${freeMovement}`, data);
  }

  async UpdateDeleteMovementRecordById(id, data, freeMovement, isCriticizedBroker) {
    return await this._httpService.put(`/${id}/holder-delete?isFreeMovement=${freeMovement}&isCriticizedBroker=${isCriticizedBroker}`, data);
  }

  async FindRemovedMovementByBatchId(params) {
    return await this._httpService.get(`?${params}`);
  }

  async FindPendingMovements(isRHProtegido) {
    return await this._httpService.get(`/pending-movements?isProtectedHR=${isRHProtegido}`);
  }

  async ResendMovement(movementRecordId) {
    return await this._httpService.patch(`resend/${movementRecordId}`);
  }

  async CheckBeneficiaryDuplicity(params) {
    return await this._httpService.post('/insert/check-beneficiary-duplicity', params);
  }

  async ForceFinish(data){
    return await this._httpService.post('/force-finish', data);
  }

  async CancelMovements(data){
    return await this._httpService.post('/cancel', data);
  }
}
