export default {
  created() {
    this.verifyRouteRHProtegidoMixin();
  },
  methods: {
    verifyRouteRHProtegidoMixin() {
      if (this.$route.query && (this.$route.query.isRHProtegido === 'true' || this.$route.query.isRHProtegido === true)) this.isRHProtegido = true;
    },
    redirectRouter(name, query, params) {
      this.$router.push({ name, query, params });
    },
  },
};
