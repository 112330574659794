/* eslint-disable */
import HttpService from '../HttpService'

export default class InsuranceCarrierMovementActionService {
  constructor() {
    this._httpService = new HttpService('/sdi/insurance-carrier-movement-action');
  }

  async FindLogsByParams(queryString) {
    return await this._httpService.get(`?${queryString}`);
  }

  async DownloadActionById(id) {
    return await this._httpService.get(`/${id}/content-download`);
  }
}
